import $ from 'jquery-validation'
import 'jquery-mask-plugin'

// Метод валидации email с правильной регуляркой
$.validator.methods.email = function(value, element) {
  return this.optional(element) || /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/.test(value);
}

$.validator.addMethod("words", function(value, element) {
	// только буквы
	return this.optional( element ) || /^[a-zа-яё\s]+$/iu.test( value );
});

// маска для телефона
function phoneMask(el) {
  el.mask('+7 (000) 000-00-00');
}

// Форма обратного звонка
var formCallme = (function () {
	var form;
	var inputs;
	var fileInput;
	var submitButton;

	function validateForm () {
		form.validate({
			rules: {
				phone: {
						minlength: 18,
						required: true
				}
			},
			errorPlacement: function (error, element) {
				return true;
			},
			submitHandler: function (form) {
				$.ajax({
					url: $(form).attr('action'),
					type: 'POST',
					dataType: 'json',
					data: new FormData($(form)[0]),
					cache: false,
					processData: false,
					contentType: false,
					beforeSend: function (data) {
						$(form).find('input[type="submit"]').prop('disabled', true);
					},
					complete: function (data) {
						$(form).find('input[type="submit"]').prop('disabled', false);
					}
				})
					.done(function (data) {
						if (!!data.success_message) {
							$(form).find('.cb-message').addClass('cb-message--visible').text(data.success_message)
							$(form)[0].reset()
							submitButton.addClass('btn--disabled');
							submitButton.attr('disabled', 'disabled');
						} else {
							$(form).find('.cb-message').addClass('cb-message--visible').text('Заполните форму правильно')
						}
            
            setTimeout(function() {
              $(form).closest('.popup').find('.popup__close').trigger('click')
            }, 5000);
					});
			}
		});
	}

	function stateButton () {
		if (form.valid()) {
			submitButton.removeClass('btn--disabled');
			submitButton.removeAttr('disabled');
		} else {
			submitButton.addClass('btn--disabled');
			submitButton.attr('disabled', 'disabled');
		}
	}

	// public
	return {
		init: function () {
			form = $('.form--callme');
			inputs = form.find('.form__input');
			fileInput = form.find('#upload-file');
			submitButton = form.find('.btn');

			validateForm();
			this.bindEvents();
		},

		bindEvents: function () {
			inputs.on('change keyup', inputs, function (event) {
				event.preventDefault()
				stateButton()
			});

      phoneMask(form.find('input[type="tel"]'))
		}
	};
}());

// Форма резюме
var formResume = (function () {
	var form;
	var inputs;
	var fileInput;
	var submitButton;

	function validateForm () {
		form.validate({
			rules: {
				name: {
						minlength: 2,
						maxlength: 100,
						words: true,
						required: true
				},
				phone: {
						minlength: 18,
						required: true
				},
				city: {
						minlength: 3,
						maxlength: 100,
						required: true
				}
			},
			errorPlacement: function (error, element) {
				return true;
			},
			submitHandler: function (form) {
				$.ajax({
					url: $(form).attr('action'),
					type: 'POST',
					dataType: 'json',
					data: new FormData($(form)[0]),
					cache: false,
					processData: false,
					contentType: false,
					beforeSend: function (data) {
						$(form).find('input[type="submit"]').prop('disabled', true);
					},
					complete: function (data) {
						$(form).find('input[type="submit"]').prop('disabled', false);
					}
				})
					.done(function (data) {
						if (!!data.success_message) {
							$(form).find('.cb-message').addClass('cb-message--visible').text(data.success_message)
							$(form)[0].reset()
							submitButton.addClass('btn--disabled');
							submitButton.attr('disabled', 'disabled');
							$(form).find('label[for="upload-file"]').text('Резюме (doc, pdf):')
							setTimeout(function() {
								$(form).closest('.popup').find('.popup__close').trigger('click')
							}, 5000);
						} else {
							$(form).find('.cb-message').addClass('cb-message--visible').text('Заполните форму правильно')
						}
					});
			}
		});
	}

	function stateButton () {
		if (form.valid()) {
			submitButton.removeClass('btn--disabled');
			submitButton.removeAttr('disabled');
		} else {
			submitButton.addClass('btn--disabled');
			submitButton.attr('disabled', 'disabled');
		}
	}

	// public
	return {
		init: function () {
			form = $('.form--resume');
			inputs = form.find('.form__input');
			fileInput = form.find('#upload-file');
			submitButton = form.find('.btn');

			validateForm();
			this.bindEvents();
		},

		bindEvents: function () {
			inputs.on('change keyup', inputs, function (event) {
				event.preventDefault()
				stateButton()
			});

      phoneMask(form.find('input[type="tel"]'))

			fileInput.change(function (e) {
				let fileName = $(e.currentTarget).val().split('\\').pop()
				$(this).closest('.form--resume').find('label[for="upload-file"]').text(fileName)
			});
		}
	};
}());

$(function() {

  if ($('.form--callme').length > 0) {
    formCallme.init()
  }

  if ($('.form--resume').length > 0) {
    formResume.init()
  }

	let openingTime = new Date();

	// Высчитывание времени проведенного на сайте
	function timeDuration (t1, t2) {
		return ((t2 - t1) / 1000);
	}

	// по клику на отправку формы в поле duration отправляется timeDuration
	$('[type="submit"]').on('click', function () {
		let thisForm = $(this).closest('form');

		var durationField = thisForm.find('[name="duration"]');

		durationField.val(timeDuration(openingTime, new Date()));
	})
})