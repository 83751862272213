import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {

  if (!!document.querySelector('label[for="upload-file"]')) {
    document.querySelector('label[for="upload-file"]').classList.add('form__input')
  }

  //popup rabota
  let popupTriggers = document.querySelectorAll('.js-popup-trigger');
  let popup = document.querySelector('.popup');
  let formResumeBlock = document.querySelector('.popup__content--resume')
  let formCallmeBlock = document.querySelector('.popup__content--callme')

  //open popup rabota
  popupTriggers.forEach(element => {
    element.addEventListener('click', function (event) {
      event.preventDefault()
      document.querySelector('html').classList.add('no-scroll')
      popup.classList.add('is-visible')

      let dataPopupType = this.getAttribute('data-popup-type')
      if (dataPopupType === 'callme') {
        formCallmeBlock.classList.remove('d-none')
        formResumeBlock.classList.add('d-none')
      } else if (dataPopupType === 'resume') {
        formResumeBlock.classList.remove('d-none')
        formCallmeBlock.classList.add('d-none')
      }
      
      if (this.classList.contains('category-vacancies__item-content__btn')) {
        let nameVacancy = this.closest('.category-vacancies__item').querySelector('.category-vacancies__item-top__title').textContent
        popup.querySelector('form').querySelector('input[name="vacancy"]').value = nameVacancy
        
        if (this.closest('.category-vacancies[data-list="vahta"]')) {
          popup.querySelector('form').querySelector('input[name="city"]').value = ''
        } else if (this.closest('.category-vacancies[data-list="graphik"]')) {
          let cityVacancy = this.closest('.category-vacancies').querySelector('.dropdown__click').textContent
          popup.querySelector('form').querySelector('input[name="city"]').value = cityVacancy
        }
      } if (this.classList.contains('expert__block__btn')) {
        popup.querySelector('form').querySelector('input[name="vacancy"]').value = ''
        popup.querySelector('form').querySelector('input[name="city"]').value = ''
      }
    })

  });

  //close popup rabota
  popup.addEventListener('click', function (event) {
    if (event.target.classList.contains('popup__close') || event.target.classList.contains('popup')) {
      event.preventDefault()
      this.classList.remove('is-visible')
      $('.cb-message').removeClass('cb-message--visible')
      document.querySelector('html').classList.remove('no-scroll')

      let forms = document.querySelectorAll('.popup__content');
      for(var i = 0; i < forms.length; i++) {
        var form = forms[i];
        if (!form.classList.contains('d-none')) {
          form.classList.add('d-none');
        }
      }
    }
  })

  // tabs
  let categoryBtns = document.querySelectorAll('.category-list__item')
  let vacanciesList = document.querySelectorAll('.category-vacancies')

  categoryBtns.forEach(element => {
    element.addEventListener('click', function (event) {
      event.preventDefault()
      let tabName = this.getAttribute('data-tab')
      let cityCategoryBlock = document.querySelector('.dropdown')

      this.classList.add('category-list__item--active')
      $(this).siblings().removeClass('category-list__item--active')

      vacanciesList.forEach(element => {
        let listName = element.getAttribute('data-list')

        if (listName === tabName) {
          element.classList.add('category-vacancies--active')
          $(element).siblings().removeClass('category-vacancies--active')
        }
      });

      if (tabName === 'graphik') {
        cityCategoryBlock.classList.add('dropdown--visible')
      } else {
        cityCategoryBlock.classList.remove('dropdown--visible')
      }
    })
  });

  // spoiler
  let vacanciesItem = document.querySelectorAll('.category-vacancies__item')

  vacanciesItem.forEach(element => {
    let clickableTop = element.querySelector('.category-vacancies__item-top')
    let content = element.querySelector('.category-vacancies__item-content')

    clickableTop.addEventListener('click', event => {
      event.preventDefault()
      content.classList.toggle('category-vacancies__item-content--show')
      element.querySelector('.category-vacancies__item-top__arrow').classList.toggle('category-vacancies__item-top__arrow--open')
    })
  });
});