// Get all the dropdown from document
document.querySelectorAll('.dropdown__toggle').forEach(dropDownFunc);

// Dropdown Open and Close function
function dropDownFunc(dropDown) {
  if (dropDown.classList.contains('dropdown__click') === true) {
    dropDown.addEventListener('click', function (e) {
      e.preventDefault();

      if (this.nextElementSibling.classList.contains('dropdown__active') === true) {
        // Close the clicked dropdown
        this.parentElement.classList.remove('dropdown__open');
        this.nextElementSibling.classList.remove('dropdown__active');

      } else {
        // Close the opened dropdown
        closeDropdown();

        // add the open and active class(Opening the DropDown)
        this.parentElement.classList.add('dropdown__open');
        this.nextElementSibling.classList.add('dropdown__active');
      }
    });
  }
};


// Listen to the doc click
window.addEventListener('click', function (e) {

  // Close the menu if click happen outside menu
  if (e.target.closest('.dropdown__container') === null) {
    // Close the opend dropdown
    closeDropdown();
  }

});


// Close the openend Dropdowns
function closeDropdown() {
  // remove the open and active class from other opened Dropdown (Closing the opend DropDown)
  document.querySelectorAll('.dropdown__container').forEach(function (container) {
    container.classList.remove('dropdown__open')
  });

  document.querySelectorAll('.dropdown__menu').forEach(function (menu) {
    menu.classList.remove('dropdown__active');
  });
}

// close the dropdown on mouse out from the dropdown list
document.querySelectorAll('.dropdown__menu').forEach(function (dropDownList) {
  // close the dropdown after user leave the list
  dropDownList.onmouseleave = closeDropdown;
});


let dropdownItems = document.querySelectorAll('.dropdown__item')


dropdownItems.forEach(element => {
  element.addEventListener('click', function (event) {
    event.preventDefault()
    let cityName = this.textContent
    let cityCode = this.getAttribute('data-city')
    let vacancies = document.querySelectorAll('.category-vacancies__item[data-city-vac]')

    this.closest('.dropdown').querySelector('.dropdown__toggle').textContent = cityName
    closeDropdown()

    vacancies.forEach(element => {
      let vacancyCity = element.getAttribute('data-city-vac')
      let arrVacancyCity = vacancyCity.split(', ')

      if (arrVacancyCity.some(elem => elem === cityCode)) {
        element.classList.remove('category-vacancies__item--hide')
      } else {
        element.classList.add('category-vacancies__item--hide')
      }
    });
  })
});